<div class="header p-d-flex p-jc-between p-ai-center">

  <div>
    <h1>
      <a routerLink="/search">
        <span style="font-weight:normal; color:#333;">EMPAMOS</span> GameReSearch
      </a>
    </h1>
  </div>

  <div>
    <app-header-user></app-header-user>
  </div>

</div>

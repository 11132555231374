<div style="background: url('/assets/analyse3-1.1600x600.jpg') no-repeat right center;background-size:cover;" class="landing_picture">
  <div class="empamos_landingpage_headline">
    <h1>
      <span style="font-weight:normal;color:#333;">EMPAMOS</span>
      GameReSearch
    </h1>
    <h2>
      <span class="subheadline_strong">Emp</span>irische
      <span class="subheadline_strong">A</span>nalyse
      <span class="subheadline_strong">mo</span>tivierender
      <span class="subheadline_strong">S</span>pielelemente
    </h2>
  </div>
</div>

<div *ngIf="!isAuthenticated; else elseBlock">
  <p>Um diese Anwendung nutzen zu können, müssen Sie sich zuerst anmelden.</p>
  <p-button (click)="login()">Login</p-button>
</div>
<ng-template #elseBlock><p>Sie sind bereits als <strong>{{userName}}</strong> angemeldet und können direkt zur Suche, oder sich abmelden, um
  mit einem anderen Account die Anwendung zu nutzen.</p>

  <p-button (click)="login()">Zur Anwendung</p-button>
  <button pButton type="button" class="p-button-text" label="zuerst abmelden" (click)="logout()"></button>
</ng-template>



<app-footer></app-footer>

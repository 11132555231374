<app-header></app-header>

<div class="layout-wrapper">

  <form (ngSubmit)="onSearch()" #f="ngForm">

    <div class="p-grid p-fluid">
      <div class="p-col">
        <div class="p-inputgroup">
          <button pButton id="search-button" type="submit" label="Suchen"></button>
          <input pInputText id="query" type="search" name="query" [(ngModel)]="query" ngModel required>
        </div>
      </div>
    </div>
    <div style="position: relative">
      <div style="position: relative">
        <p-multiSelect *ngIf="years.length>0"
                       [options]="years"
                       [(ngModel)]="selectedYears"
                       [defaultLabel]="'Jahr'"
                       optionLabel="value"
                       (onChange)="onChange()"
                       name="selectFacetYear"
                       [filter]="false"></p-multiSelect>

        <p-multiSelect *ngIf="publishers.length>0"
                       [options]="publishers"
                       [(ngModel)]="selectedPublisher"
                       [defaultLabel]="'Verlag'"
                       optionLabel="value"
                       (onChange)="onChange()"
                       name="selectFacetPublisher"
                       [filter]="false"></p-multiSelect>

        <p-multiSelect *ngIf="types.length>0"
                       [options]="types"
                       [(ngModel)]="selectedTypes"
                       [defaultLabel]="'Typ'"
                       optionLabel="value"
                       (onChange)="onChange()"
                       name="selectFacetTitle"
                       [filter]="false"></p-multiSelect>

        <ng-container *ngIf="minPageNumber!=maxPageNumber">
          <div class="page-range">
            <p>Seiten: {{pageRangeValues[0]}} bis {{pageRangeValues[1]}}</p>
            <p-slider [(ngModel)]="pageRangeValues"
                      [min]="minPageNumber"
                      [max]="maxPageNumber"
                      [range]="true"
                      (onSlideEnd)="onChange()"
                      [style]="{'width':'100%', 'display':'inline-block'}"
                      name="selectPageNumber"></p-slider>
          </div>
        </ng-container>

      </div>

      <p-selectButton *ngIf="entriesFound"
                      [options]="viewModes"
                      [(ngModel)]="selectedViewMode"
                      name="selectViewMode"></p-selectButton>

    </div>
  </form>

  <p *ngIf="entriesFound"> Es wurden {{entriesFound}} Einträge gefunden.</p>

  <p *ngIf="nothingFound && entriesFound==0">Es wurden keine Einträge gefunden.</p>


    <app-result-list *ngIf="selectedViewMode=='list'"
                     [items]="items"
    ></app-result-list>

    <app-result-tiles *ngIf="selectedViewMode=='tile'"
                      [items]="items"
    ></app-result-tiles>

  <p-paginator *ngIf="entriesFound"
               #paginator
               [rows]="resultsPerPage"
               [totalRecords]="entriesFound"
               (onPageChange)="onPaginate($event)">

  </p-paginator>

</div>

<app-footer></app-footer>

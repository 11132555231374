import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {SearchComponent} from './search/search.component';
import {PdfViewerComponent} from './pdf-viewer/pdf-viewer.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {PolicyComponent} from './policy/policy.component';
import {AutoLoginPartialRoutesGuard} from 'angular-auth-oidc-client';

const appRoutes: Routes = [
  { path: '', redirectTo: 'welcome', pathMatch: 'full' },
  { path: 'welcome', component: WelcomeComponent },
  { path: 'privacy', component: PrivacyComponent },
  { path: 'policy', component: PolicyComponent },
  { path: 'search', component: SearchComponent , canActivate: [AutoLoginPartialRoutesGuard] },
  { path: 'pdf', component: PdfViewerComponent, canActivate: [AutoLoginPartialRoutesGuard] },
  { path: '**', component: SearchComponent}
  ];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

import {Component, Input, OnInit} from '@angular/core';
import {InstructionEntry} from '../instruction-entry';

@Component({
  selector: 'app-result-tiles',
  templateUrl: './result-tiles.component.html',
  styleUrls: ['./result-tiles.component.css']
})
export class ResultTilesComponent implements OnInit {
  @Input() items: InstructionEntry[];

  constructor() { }

  ngOnInit(): void {
  }

}

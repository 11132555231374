<div class="footer p-d-flex p-jc-start p-ai-start">

  <div>

    <div>
      <button pButton routerLink="/privacy" type="button" label="Datenschutz" class="p-button-link p-button-sm"></button>
      <button pButton routerLink="/policy" type="button" label="Nutzungsbedingungen" class="p-button-link p-button-sm"></button>
      <a href="https://www.th-nuernberg.de/fakultaeten/in/" target="_blank" rel="noopener">
        <button pButton label="Fakultät Informatik" class="p-button-link p-button-sm"></button>
      </a>
      <a href="https://empamos.in.th-nuernberg.de" target="_blank" rel="noopener">
        <button pButton label="EMPAMOS" class="p-button-link p-button-sm"></button>
      </a>
    </div>

    <div class="p-ml-3">
      GameReSearch UI Version {{version}}
    </div>
  </div>

  <div class="p-ml-3 p-pt-2">
    <img src="assets/th-banner.png" width="70%">
  </div>
</div>



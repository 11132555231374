import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import * as fileSaver from 'file-saver';

import {MessageService} from 'primeng/api';

import {InstructionEntry} from '../../instruction-entry';
import {environment} from '../../../../environments/environment';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {DownloadService} from '../../../download.service';
import {TaggingService} from '../pattern-tagging/tagging.service';

@Component({
  selector: 'app-result-list-item',
  templateUrl: './result-list-item.component.html',
  styleUrls: ['./result-list-item.component.css']
})
export class ResultListItemComponent implements OnInit, OnDestroy {
  @Input() item: InstructionEntry;
  @ViewChild('currentDiv') currentDiv: ElementRef;

  private subscriptions = new Subscription();

  showMore: boolean;
  textSnippets: string;

  showPatternSelectionDialog = false;
  dirtyDialog: boolean;

  //
  allowTagging = false;
  allowViewTags = false;
  allowDownloadPdf = false;
  allowDownloadText = false;

  constructor(private oidcSecurityService: OidcSecurityService,
              private download: DownloadService,
              private taggingService: TaggingService,
              private messageService: MessageService) {
    this.showMore = false;
  }

  ngOnInit(): void {
    this.textSnippets = this.createSnippetsText(environment.snippetsPerItemDefault);

    this.subscriptions.add(this.oidcSecurityService.userData$.subscribe(
      (userDataResult) => {
        if (userDataResult.userData != null) {

          if (userDataResult.userData.groups.includes('research-tags-edit')) {
            this.allowTagging = true;
          }

          if (userDataResult.allUserData[0].userData.groups.includes('research-tags-view')) {
            this.allowViewTags = true;
          }

          if (userDataResult.allUserData[0].userData.groups.includes('research-download-pdf')) {
            this.allowDownloadPdf = true;
          }

          if (userDataResult.allUserData[0].userData.groups.includes('research-download-text')) {
            this.allowDownloadText = true;
          }
        }
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Create the text for the more/less link.
   */
  linkText() {
    const numberOfHiddenSnippets = this.item.snippets.length - environment.snippetsPerItemDefault;
    if (numberOfHiddenSnippets > 0) {
      if (!this.showMore) {
        return String(numberOfHiddenSnippets).concat(' weitere Schnipsel...');
      } else {
        return 'weniger Schnipsel';
      }
    }
  }

  /**
   * Toggles number of snippets for a spear entry.
   */
  updateTextSnippets() {
    if (!this.showMore) {
      this.textSnippets = this.createSnippetsText(Number.MAX_VALUE);
    } else {
      this.currentDiv.nativeElement.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
      this.textSnippets = this.createSnippetsText(environment.snippetsPerItemDefault);
    }
    this.showMore = !this.showMore;
  }

  /**
   * Create a single formatted html string from the list of highlights to be shown to user.
   * @param n Number with how many highlight snippets shall be used for textSnippets.
   */
  private createSnippetsText(n: number) {
    if (this.item.snippets === undefined) {
      return '<i>Keine Hervorhebungen für den Suchbegriff vorhanden.</i>';
    } else {
      const selectedSnippets = this.item.snippets.slice(0, n);
      return '<strong>[</strong>' + selectedSnippets.join('<strong>]   [</strong>') + '<strong>]</strong>';
    }
  }

  downloadPdf(uid: string) {
    this.download.pdf(uid).subscribe(
      response => {
        const blob: any = new Blob([response], {type: 'applicaton/pdf'});
        fileSaver.saveAs(blob, uid + '.pdf');
      });
  }

  downloadText(uid: string) {
    this.download.text(uid).subscribe(
      response => {
        const blob: any = new Blob([response], {type: 'text/plain;charset=UTF-8'});
        fileSaver.saveAs(blob, uid + '.txt');
      });
  }

  dialogIsDirty($event) {
    this.dirtyDialog = $event;
  }

  dialogOk(): void {
    this.showPatternSelectionDialog = false;

    if (this.dirtyDialog === true) {
      this.taggingService.tag(this.item.uid, this.item.patternTags).subscribe(
        () => {
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: `${this.item.patternTags.length} Pattern für ${this.item.title} gespeichert.`
          });
          this.dirtyDialog = false;
        }
      );
    }
  }

}

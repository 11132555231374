import {Component} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  constructor(private oidcSecurityService: OidcSecurityService) { }

  logout() {
    this.oidcSecurityService.logoff();
  }

}

import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private http: HttpClient) { }

  pdf(id: string): Observable<any> {
    return this.http.get('/api/game/' + id + '/pdf', {responseType: 'blob'});
  }

  text(id: string): Observable<string> {
    return this.http.get('/api/game/' + id + '/text', {responseType: 'text'});
  }
}

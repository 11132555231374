<app-header></app-header>

<div>
  <h1>Datenschutzerklärung</h1>
  <p>
    Dieser Service ist des Teil des Forschungsprojektes <a href="https://empamos.in.th-nuernberg.de">EMPAMOS</a> mit
    dem Ziel Spielmechaniken zu erforschen. Dieses Teilprojekt ermöglicht es unseren Bestand an Spielanleitungen
    systematisch zu durchsuchen und zu annotieren.
  </p>

  <p>Für die Nutzung dieser Anwendung werden Daten im Rahmen der <a href="https://www.th-nuernberg.de/datenschutz/" target="_blank" rel="noopener">Datenschutzerklärung der TH
    Nürnberg</a> erhoben.</p>

  <p>Zusätzlich werden im Rahmen des EMPAMOS-Projektes Daten gespeichert, die explizit oder implizit an uns übertragen
    werden. Bitte lesen Sie sich die Datenschutzerklärung sorgfältig durch.</p>

  <h2>Von uns gespeicherte Daten</h2>
  Bei der Nutzung dieses Teilprojektes von EMPAMOS erheben wir folgende Daten.

  <h3>Nutzerdaten</h3>
  Die Anmeldung erfolgt über einen zentralen Anmeldeserver. Nach erfolgreicher Anmeldung speichern wir aus den vom
  Anmeldeserver übermittelten Nutzerdaten in unserem System:
  <ul>
    <li>Ihren Namen</li>
    <li>Ihre ID auf dem Anmeldeserver</li>
  </ul>

  <h3>Explizite Protokollierung</h3>
  <ul>
    <li>Datum letzter und erstmaliger Anmeldung.</li>
    <li>Wer, wenn ein Spielpattern bearbeitet hat.</li>
    <li>Wer, wann ein Meta-Datum (Titel, Untertitel) eingeben hat.</li>
  </ul>

  <h3>Implizit Protokollierung</h3>
  Beim Aufruf unseres Service werden in Proxies, Application Servern, Caches, und Routern Protokolldaten gespeichert,
  die Datum, IP-Adresse, Betriebssysteme und von ihrem Browser im Rahmen der Anfrage übertragenen Zusatzinformationen
  enthalten kann.

  <h2>Nutzung der personenbezogenen Daten</h2>
  <p>
    Wir nutzen die erhobenen Daten ausschließlich in der Fakultät Informatik für die Arbeit im Projekt EMPAMOS.
  </p>

  <h2>Weitergabe von Daten</h2>
  <p>
    Eine Weitergabe von personenbezogenen Daten an Dritte findet nicht statt.
  </p>

  <p>Eine Ausnahme hiervon bilden
    gesetzliche Regelungen, an die wir gebunden sind und denen wir bei rechtmäßiger Aufforderung nachkommen müssen.
  </p>

  <p>
    Annonymisierte Daten bzw. Daten ohne Personenbezug wie z.B. Zugriffszahlen auf das System usw. können
    veröffentlicht, für Forschungsprojekte verwendet oder zur Verbesserung der bereitgestellten Informationen verwendet
    werden.
  </p>

  <h2>Datenübertragung</h2>
  Wir verwenden zur Nutzung dieses Dienstes ausschließlich verschlüsselte Verbindungen, um ein Auslesen der
  übertragenen Daten von Dritten zu verhindern.

  <h2>Cookies</h2>
  Wir verwenden Cookies nur zum Zwecke der Authentifizierung. Sie können diese Cookies löschen
  wenn Sie möchten, aber Sie müssen sich danach erneut anmelden.

  Tracking Cookies von dritter Seite kommen nicht zum Einsatz.
</div>

<button pButton routerLink="/search" type="button" label="Zur Anwendung" class="p-button-link"></button>

<app-footer></app-footer>

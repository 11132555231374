<p-table #dt1 [value]="selection"
         [globalFilterFields]="['pattern.pattern_name']"
         styleClass="p-datatable-sm"
         [scrollable]="true">
  <ng-template pTemplate="caption">
    <div class="p-d-flex">
                <span class="p-input-icon-left p-ml-auto">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
                           placeholder="Pattern filtern"
                           class="p-inputtext-sm"/>
                </span>
    </div>
  </ng-template>
  <ng-template pTemplate="body" let-current>
    <tr>
      <td>
        <p-triStateCheckbox
          [(ngModel)]="current.evidence"
          (onChange)="onChange(current)"
          [ngClass]="current.evidence ? 'positive' : 'negative'"
        ></p-triStateCheckbox>
      </td>
      <td>{{current.pattern.pattern_name}}</td>
    </tr>
  </ng-template>
</p-table>




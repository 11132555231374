// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  /**
   * Default number of found entries shown on a page.
   */
  resultsPerPageDefault: 10,

  /**
   * Default number of shown snippets for an entry.
   */
  snippetsPerItemDefault: 10,

  /**
   * Default for maximum snippets per entry are requested from solr.
   */
  snippetMaxNumberDefault: 500,

  /**
   * Maximum chars for a single snippet.
   */
  snippetFragsizeDefault: 200
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

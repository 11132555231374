import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ServiceUrls} from '../../../service-urls';
import {PatternTag} from './pattern-tag';
import {GameTagTuple} from './game-tag-tuple';

@Injectable({
  providedIn: 'root'
})
export class TaggingService {

  apiTagsUrl = ServiceUrls.API_BASE + ServiceUrls.ENDPOINT_TAGS

  constructor(private http: HttpClient) { }

  /**
   * Tags a game with pattern.
   * @param gameUid String with UID which game should be tagged.
   * @param tags List with pattern tags.
   */
  tag(gameUid: string, tags: PatternTag[]) {
    return this.http.post<GameTagTuple>(this.apiTagsUrl, {gameUid, patternTags: tags});
  }
}

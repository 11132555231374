import {Component, OnDestroy, OnInit} from '@angular/core';
import {OidcSecurityService} from 'angular-auth-oidc-client';
import {Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.css']
})
export class WelcomeComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  isAuthenticated = false;
  userName: string;
  loginButtonText = 'Login';

  constructor(public oidcSecurityService: OidcSecurityService,
              public router: Router) { }

  ngOnInit(): void {

    this.subscriptions.add(this.oidcSecurityService.isAuthenticated$.subscribe(({ isAuthenticated }) => {
      this.isAuthenticated = isAuthenticated;

      console.warn('WelcomeComponent::ngOnInitauthenticated: ', isAuthenticated);
    }));

    this.subscriptions.add(this.oidcSecurityService.userData$.subscribe((userDataResult) => {
        //console.log('userDataResult::', userDataResult);
        //if ( userDataResult != null ) {
        //  if ( userDataResult.allUserData[0].userData != null ) {
        //    this.userName = userDataResult.allUserData[0].userData.userName;
        //  }
        //}
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  login() {
    if (this.isAuthenticated) {
      this.router.navigate(['search']);
    }
    else {
      this.oidcSecurityService.authorize();
    }
  }

  logout() {
    this.oidcSecurityService.logoff();
  }

}

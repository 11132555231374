import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {OidcSecurityService} from 'angular-auth-oidc-client';

@Component({
  selector: 'app-header-user',
  templateUrl: './header-user.component.html',
  styleUrls: ['./header-user.component.css']
})
export class HeaderUserComponent implements OnInit, OnDestroy {

  private subscriptions = new Subscription();

  userName: string;
  loggedIn: boolean;

  constructor(private oidcSecurityService: OidcSecurityService) { }

  ngOnInit(): void {

    this.subscriptions.add(this.oidcSecurityService.isAuthenticated$.subscribe(
      ({ isAuthenticated }) => {
          this.loggedIn = isAuthenticated;
          console.warn('HeaderComponent::ngOnInitauthenticated: ', isAuthenticated);
      })
    );

    this.subscriptions.add(this.oidcSecurityService.userData$.subscribe((userDataResult) => {
      console.log('userDataResult::', userDataResult);
      if ( userDataResult != null) {
        if ( userDataResult.userData != null ) {
          this.userName = userDataResult.userData.name;
        }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  logout(): void {
    this.oidcSecurityService.logoff();
  }

  login(): void {
    this.oidcSecurityService.authorize();
  }
}

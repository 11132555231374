import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';

import {BrowserModule} from '@angular/platform-browser';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';


import {MultiSelectModule} from 'primeng/multiselect';
import {InputTextModule} from 'primeng/inputtext';
import {ButtonModule} from 'primeng/button';
import {SelectButtonModule} from 'primeng/selectbutton';
import {PaginatorModule} from 'primeng/paginator';
import {SliderModule} from 'primeng/slider';
import {TooltipModule} from 'primeng/tooltip';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {TableModule} from 'primeng/table';
import {DialogModule} from 'primeng/dialog';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {MessageService} from 'primeng/api';

import {WelcomeComponent} from './welcome/welcome.component';
import {AuthConfigModule} from './auth-config.module';
import {AuthInterceptor} from './auth/auth-interceptor';
import {HeaderUserComponent} from './auth/header-user/header-user.component';
import {AuthImagePipe} from './auth/auth-image-pipe';
import {SecImageComponent} from './auth/sec-image/sec-image.component';
import {PrivacyComponent} from './privacy/privacy.component';
import {PolicyComponent} from './policy/policy.component';
import {ResultListItemComponent} from './search/result-list/result-list-item/result-list-item.component';
import {AppRoutingModule} from './app-routing.module';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {SearchComponent} from './search/search.component';
import {PatternViewerComponent} from './search/result-list/pattern-tagging/pattern-viewer/pattern-viewer.component';
import {PatternSelectionComponent} from './search/result-list/pattern-tagging/pattern-selection/pattern-selection.component';
import {ResultListComponent} from './search/result-list/result-list.component';
import {ResultTilesComponent} from './search/result-tiles/result-tiles.component';
import {PdfViewerComponent} from './pdf-viewer/pdf-viewer.component';
import {ErrorInterceptor} from './error-interceptor.service';
import {ToastModule} from 'primeng/toast';


@NgModule({
  declarations: [
    AppComponent,
    SearchComponent,
    ResultListComponent,
    ResultListItemComponent,
    FooterComponent,
    HeaderComponent,
    ResultTilesComponent,
    PdfViewerComponent,
    WelcomeComponent,
    HeaderUserComponent,
    AuthImagePipe,
    SecImageComponent,
    PatternViewerComponent,
    PatternSelectionComponent,
    PrivacyComponent,
    PolicyComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    MultiSelectModule,
    SelectButtonModule,
    ButtonModule,
    InputTextModule,
    SliderModule,
    PaginatorModule,
    TooltipModule,
    OverlayPanelModule,
    TableModule,
    DialogModule,
    ToastModule,
    TriStateCheckboxModule,
    AppRoutingModule,
    AuthConfigModule
  ],
  providers: [
    MessageService,
    AuthInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

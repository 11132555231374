<app-header></app-header>

<div>
<h1>Nutzungsbedingungen</h1>

<p>
  Diese Nutzungsbedingungen beziehen sich das Teilprojekt GameResearch aus dem Forschungsprojekt EMPAMOS
  der Fakultät Informatik an der Technischen Hochschule Nürnberg Georg Simon Ohm.
</p>

<p>
  Bitte lesen Sie sich die hier aufgeführten Nutzungsbedingungen sorgfältig durch. Falls Sie mit einem der hier
  aufgeführten Punkte nicht einverstanden sind, dürfen Sie diesen Dienst nicht nutzten.
</p>

<p>
  Die hier aufgeführten Nutzungsbedingungen beziehen sich nur auf das Teilprojekt GameReSearch. Wenn Sie weitere
  Dienste nutzen, auf die wir verlinken, sind die dort angegebenen Nutzungsbedingungen maßgebend.
</p>

<p>
  Sie dürfen die Ihnen zugänglich gemachten Inhalte nur für die Forschungsarbeit im Rahmen
  des EMPAMOS-Projektes nutzen. Nach Abschluss Ihrer Arbeit, müssen die von Ihnen heruntergeladenen Daten gelöscht
  werden.
</p>

<p>
  Eine Weitergabe von Daten aus dem Projekt an dritte ist nicht gestattet. Es Ausnahme bilden Veröffentlichungen
  und Arbeiten im Rahmen von Forschungsarbeiten, zu denen Sie beauftragt wurden.
</p>

<p>
  Sollte eine Bestimmung dieser Nutzungsbedingungen unwirksam sein, wird die Wirksamkeit der übrigen Bedingungen
  davon nicht berührt. Anstelle der unwirksamen Bedingung tritt eine dieser Bedingung möglichst nahekommende Bedingung
  an ihrer Stelle in Kraft.
</p>
</div>

<button pButton routerLink="/search" type="button" label="Zur Anwendung" class="p-button-link"></button>

<app-footer></app-footer>

<div class="p-grid p-align-start research-list-element">

  <div class="p-col-fixed" style="width: 164px">
    <app-sec-image src="/api/game/{{item.uid}}/thumbnail"></app-sec-image>
  </div>

  <div class="p-col" #currentDiv>
    <h2>{{item.title}}</h2>
    <p class="research-sourceuid">{{item.uid}}</p>
    <p>
      <span [innerHTML]="textSnippets"></span>

      <a *ngIf="item.snippets != undefined"
         (click)="updateTextSnippets()"
         class="p-mr-5">
        {{linkText()}}</a>
    </p>


    <p-dialog *ngIf="showPatternSelectionDialog && allowTagging"
              [visible]="showPatternSelectionDialog"
              [closable]="false"
              [header]="item.uid"
              modal="true"
              [style]="{width: '60vw'}"
              [baseZIndex]="10000"
              position="top">

      <app-pattern-selection *ngIf="showPatternSelectionDialog"
                             [uid]="item.uid"
                             [tags]="item.patternTags"
                             (dialogIsDirty)="dialogIsDirty($event)">
      </app-pattern-selection>

      <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="dialogOk()" label="Ok" styleClass="p-button-text"></p-button>
      </ng-template>

    </p-dialog>

    <app-pattern-viewer *ngIf="allowViewTags"
      [patterns]="item.patternTags">
    </app-pattern-viewer>

    <div>
      <button *ngIf="allowTagging" pButton label="Pattern bearbeiten"  icon="pi pi-pencil" class="p-button-text p-button-sm" (click)="showPatternSelectionDialog=true"></button>
      <button *ngIf="allowDownloadPdf" pButton label="PDF" icon="pi pi-file-pdf" class="p-button-text p-button-sm" (click)="downloadPdf(item.uid)"></button>
      <button *ngIf="allowDownloadText" pButton label="Text" icon="pi pi-file" class="p-button-text p-button-sm" (click)="downloadText(item.uid)"></button>
    </div>

  </div>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {PatternTag} from '../pattern-tag';

@Component({
  selector: 'app-pattern-viewer',
  templateUrl: './pattern-viewer.component.html',
  styleUrls: ['./pattern-viewer.component.css']
})
export class PatternViewerComponent implements OnInit {
  @Input() patterns: PatternTag[];

  selectedPatternIds: PatternTag[] = [];

  ngOnInit(): void {
    // explicitly sort alphabetically elements before viewing because server may deliver in arbitrary order.
    this.patterns.sort( (a, b) => (a.pattern.pattern_name < b.pattern.pattern_name) ? -1 : 1 );
    for (const pattern of this.patterns) {
      this.selectedPatternIds.push(pattern);
    }
  }
}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SearchService} from './search.service';
import {NgForm} from '@angular/forms';
import {Subscription} from 'rxjs';
import {FacetEntry} from './facet-entry.model';
import {SelectItem} from 'primeng/api';
import {InstructionEntry} from './instruction-entry';
import {Paginator} from 'primeng/paginator';
import {ActivatedRoute, Router} from '@angular/router';
import {environment} from '../../environments/environment';
import {OidcSecurityService} from 'angular-auth-oidc-client';


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
})
export class SearchComponent implements OnInit, OnDestroy {
  @ViewChild('f') searchForm: NgForm;
  @ViewChild('paginator') paginator: Paginator;

  // user entered search query in search field
  query: string;

  // last user entered search query to prevent search for the same text twice
  lastQuery: string;

  entriesFound: number;

  // is set true, when searched but no results and is needed to distinguish from entriesFound = 0 because of default.
  nothingFound: boolean;
  minPageNumber: number;
  maxPageNumber: number;

  items: InstructionEntry[];
  highlighting: object;

  years: FacetEntry[] = [];
  selectedYears: FacetEntry[] = [];

  publishers: FacetEntry[] = [];
  selectedPublisher: FacetEntry[] = [];

  types: FacetEntry[] = [];
  selectedTypes: FacetEntry[] = [];

  // values selected by user in slider
  pageRangeValues: number[] = [];

  viewModes: SelectItem[];
  selectedViewMode: string;

  // remember if the paginator was used for one result to prevent retrieving data from solr twice on first page.
  paginatorUsed =  false;
  resultsPerPage: number;

  private solrSubscription: Subscription;

  constructor(private searchService: SearchService,
              private router: Router,
              private activeRoute: ActivatedRoute,
              public oidcSecurityService: OidcSecurityService) {

    // prepare static data for ui element to select view mode
    this.viewModes = [];
    this.viewModes.push({label: 'Liste', value: 'list'});
    this.viewModes.push({label: 'Kacheln', value: 'tile'});
    this.selectedViewMode = 'list';

    this.resultsPerPage = environment.resultsPerPageDefault;
    this.nothingFound = false;
  }

  ngOnInit(): void {

    console.log('search component init');

    /** register for updates in url to trigger search query. Also needed to enable URLs which can be
        send by mail with queries. */
    this.activeRoute.queryParams
      .subscribe( x => {
        console.log('handle url event: query params');
        console.log(x);

        this.paginatorUsed = false;

        this.query = x['query'];
        this.lastQuery = this.query;
        if(this.query != undefined) {
          this.searchService.search(x['query'], 0);
        }
      });

    /** register for finished solr queries to update result page. */
    this.solrSubscription = this.searchService.solrResponseSubject
      .subscribe( x => {


        console.log("handle search response");
        console.log(x);

        this.entriesFound = x.numberOfFoundEntries;

      // this prevent a nothing found message when so search was made, e.g. first opening
        if(this.entriesFound == 0 && this.query != undefined) {
          this.nothingFound = true;
        }
        this.items = x.results;
      /*
      if(this.pageRangeValues.length === 0) {
        this.minPageNumber = x.stats.stats_fields.pages.min;
        this.maxPageNumber = x.stats.stats_fields.pages.max;
        if (this.minPageNumber != null && this.maxPageNumber != null){
          this.pageRangeValues = [this.minPageNumber, this.maxPageNumber];
        }
      }
*/
    });

  }

  ngOnDestroy(): void {
    this.solrSubscription.unsubscribe();
  }

  onSearch() {

    if (this.query == this.lastQuery) {
      return
    }
    this.lastQuery = this.query;

    this.query = this.searchForm.value.query;

    // is called when search button is pressed. reset all detail search options.
    this.years = [];
    this.selectedYears = [];
    this.publishers = [];
    this.selectedPublisher = [];
    this.types = [];
    this.selectedTypes = [];
    this.pageRangeValues = [];
    this.paginatorUsed = false;
    this.nothingFound = false;

    // widget does not exist if no entries are found.
    if (this.paginator) {
      // otherwise reset to first page
      this.paginator.changePage(0);
    }

    // search is triggered by change the query params
    this.router.navigate([], {
      relativeTo: this.activeRoute,
      queryParams: {
        query: this.query
      },
      queryParamsHandling: 'merge'});

  }

  onChange() {
    console.log("on change multiselect");
    console.log(this.selectedYears);
    this.searchService.search(
      this.searchForm.value.query,
      0);
  }

  onPaginate(event) {
    console.log(event);
    const offset = event.page * environment.resultsPerPageDefault;

    // only reload page if not the first one. otherwise this page is retrieved twice from solr.
    if (offset !== 0 || this.paginatorUsed) {
      this.paginatorUsed = true;
      this.searchService.search(this.searchForm.value.query, offset);
      this.items = null; // clear old results

      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

    }
  }

}

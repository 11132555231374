import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Pattern} from '../../pattern';
import {map, shareReplay} from 'rxjs/operators';
import {ServiceUrls} from '../../../service-urls';

/**
 * Provides access to game pattern on remote ReSearch server.
 */
@Injectable({
  providedIn: 'root'
})
export class PatternService {

  apiUrl = ServiceUrls.API_BASE + '/pattern';

  private cachedPatterns: Observable<Pattern[]>;

  constructor(private http: HttpClient) { }

  /**
   * Retrieve all game pattern in alphabetically order.
   */
  allPatterns(): Observable<Pattern[]> {
    if (!this.cachedPatterns) {
      this.cachedPatterns = this.http.get<Pattern[]>(this.apiUrl).pipe(
        // sort alphabetically
        map(x => x.sort( (a, b) => (a.pattern_name < b.pattern_name) ? -1 : 1)),
        shareReplay()
      );
    }
    return this.cachedPatterns;
  }
}

import {Component, Input, OnDestroy, OnInit, Output, EventEmitter} from '@angular/core';
import {PatternService} from '../pattern.service';
import {PatternTag} from '../pattern-tag';
import {Subscription} from 'rxjs';


@Component({
  selector: 'app-pattern-selection',
  templateUrl: './pattern-selection.component.html',
  styleUrls: ['./pattern-selection.component.css']
})
export class PatternSelectionComponent implements OnInit, OnDestroy {
  @Input() uid: string;
  @Input() tags: PatternTag[];
  @Output() dialogIsDirty = new EventEmitter<boolean>();

  private subscriptions = new Subscription();

  selection: PatternTag[] = [];

  constructor(private patternService: PatternService) { }

  ngOnInit(): void {
    this.subscriptions.add(this.patternService.allPatterns().subscribe(
      all => {
        for (const current of all ) {
          let evidence: boolean = null;
          const found = this.tags.find(x => x.pattern.pattern_id === current.pattern_id);
          if (found) {
            evidence = found.evidence;
          }
          this.selection.push( { pattern: current, evidence} );
        }
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  onChange(current: any) {

    // signal parent that server update is requied now.
    this.dialogIsDirty.emit(true);

    this.tags.length = 0;
    for (const x of this.selection) {
      if (x.evidence != null) {
        this.tags.push(x);
      }
    }
  }

}

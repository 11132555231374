import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-sec-image',
  template: '<img [src]="src | authImage | async" [alt]="alt" [width]="width">'
})
export class SecImageComponent implements OnInit {
  @Input() src: string;
  @Input() width = '150';
  @Input() alt = '';


  ngOnInit(): void {
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {QueryResponse} from './solr-response.model';
import {Subject} from 'rxjs';
import {ServiceUrls} from '../service-urls';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  solrResponseSubject = new Subject<QueryResponse>();

  readonly apiUrl = ServiceUrls.API_BASE + ServiceUrls.ENDPOINT_SEARCH_ACTION;

  // contains http params used for all queries to solr backend.
  private globalSearchParams = new HttpParams();

  constructor(private http: HttpClient) {
  }


  /**
   * Triggers a search on SOLR index with given parameters to search for catalogues.
   *
   * This method does not have an return value but fires a subject (solrResponseSubject).
   *
   * @param query - The query text the user entered to search for.
   * @param start - Index of first entry to be returned as result.
   */
  search(query: string, start: number) {

    console.log('send search query: ' + query);

    let searchParams = this.globalSearchParams.append('q', query);
    searchParams = searchParams.append('start', start.toString());

    return this.http.get<QueryResponse>(this.apiUrl,
      {
        params: searchParams
      }).subscribe( x => {
        console.log('handle search http get');
        this.solrResponseSubject.next(x);
    });
  }

}

